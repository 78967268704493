// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-google-tag-manager-data-layer-shopify-js": () => import("./../../../src/pages/learn/google-tag-manager-data-layer-shopify.js" /* webpackChunkName: "component---src-pages-learn-google-tag-manager-data-layer-shopify-js" */),
  "component---src-pages-learn-how-to-remove-google-tag-manager-snippets-from-shopify-js": () => import("./../../../src/pages/learn/how-to-remove-google-tag-manager-snippets-from-shopify.js" /* webpackChunkName: "component---src-pages-learn-how-to-remove-google-tag-manager-snippets-from-shopify-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-shopify-google-analytics-4-js": () => import("./../../../src/pages/shopify-google-analytics-4.js" /* webpackChunkName: "component---src-pages-shopify-google-analytics-4-js" */),
  "component---src-templates-blogs-template-js": () => import("./../../../src/templates/blogsTemplate.js" /* webpackChunkName: "component---src-templates-blogs-template-js" */)
}

